import { createRouter, createWebHistory } from "vue-router";

window.onerror = function(message, source, lineno, colno, error) {
  console.log('error message:', {message, source, lineno, colno, error});
}

const routes = [
  {
    path: "/",
    name: "HlavniStrana",
    component: () =>
        import("../views/Hlavni-Strana.vue"),
  },
  {
    path: "/o-nas/",
    name: "ONas",
    component: () =>
        import("../views/O-Nas.vue"),
  },
  {
    path: "/novinky/",
    name: "Novinky",
    component: () =>
        import("../views/Novinky/Index.vue"),
  },
  {
    path: "/novinky/dulezita-sdeleni",
    name: "NovinkyDulezitaSdeleni",
    component: () =>
      import("../views/Novinky/DulezitaSdeleni.vue"),
  },
  {
    path: "/novinky/prostory-a-plochy",
    name: "NovinkyProstoryAPlochy",
    component: () =>
      import("../views/Novinky/NabidkaNemovitosti.vue"),
  },
  {
    path: "/novinky/:s_category/:s_article",
    name: "NovinkyProstoryAPlochyDetail",
    slug: "/novinky/prostory-a-plochy",
    component: () =>
        import("../views/Novinky/Detail.vue"),
  },
  {
    path: "/novinky/vyberova-rizeni",
    name: "NovinkyVyberovaRizeni",
    component: () =>
      import("../views/Novinky/VyberovaRizeni.vue"),
  },
  {
    path: "/novinky/planovane-projekty",
    name: "NovinkyPlanovaneProjekty",
    component: () =>
      import("../views/Novinky/PlanovaneProjekty.vue"),
  },
  {
    path: "/sluzby",
    name: "Sluzby",
    component: () =>
      import("../views/PoskytovaneSluzby.vue"),
  },
  {
    path: "/dokumenty",
    name: "Dokumenty",
    redirect: '/dokumenty/prevody-druzstevniho-podilu',

    children: [{
        path: "/dokumenty/prevody-druzstevniho-podilu",
        name: "DokumentyPrevodyDruzstevnihoPodilu",
        component: () =>
          import("../views/Dokumenty/PrevodyDruzstevníhoPodilu.vue"),
      },
      {
        path: "/dokumenty/podnajem-druzstevni-garaze",
        name: "DokumentyPodnajemDruzstevniGaraze",
        component: () =>
            import("../views/Dokumenty/PodnajemDruztevniGaraze.vue"),
      },
      {
        path: "/dokumenty/stanovy",
        name: "DokumentyStanovy",
        component: () =>
            import("../views/Dokumenty/Stanovy.vue"),
      },
      {
        path: "/dokumenty/shromazdeni-delegatu",
        name: "DokumentyShromazdeniDelegatu",
        component: () =>
            import("../views/Dokumenty/ShromazdeniDelegatu.vue"),
      },
      {
        path: "/dokumenty/vyrocni-zprava",
        name: "DokumentyVyrocniZprava",
        component: () =>
            import("../views/Dokumenty/VyrocniZprava.vue"),
      },
      {
        path: "/dokumenty/ruzne",
        name: "DokumentyRuzne",
        component: () =>
            import("../views/Dokumenty/Ruzne.vue"),
      },
    ]
  },
  {
    path: "/caste-dotazy",
    name: "CasteDotazy",
    component: () =>
      import("../views/CastoKladeneOtazky.vue"),
  },
  {
    path: "/spravovane-nemovitosti",
    name: "SpravovaneNemovitosti",
    component: () =>
      import("../views/SpravovaneNemovitosti.vue"),
  },
  {
    path: "/detail-nemovitosti/:s_uri",
    slug: "/detail-nemovitosti",
    name: "DetailNemovitosti",
    component: () =>
        import("../views/DetailNemovitosti.vue"),
  },
  {
    path: "/kontakt",
    name: "Kontakty",
    component: () =>
      import("../views/Kontakty.vue"),
  },
  {
    path: "/prostory_a_plochy/:patchMatch(.*)*",
    slug: "/prostory_a_plochy",
    name: 'ProstoryAPlochy-Detail',
    component: () =>
      import("../views/ProstoryAPlochy.vue"),
  },
  {
    path: "/:patchMatch(.*)*",
    slug: "/not-found",
    name: 'not-found',
    component: () =>
      import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
