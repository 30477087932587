<style>

  @font-face {
    font-family: 'HelveticaNeueLTPro-Bd';
    src: url('~@/assets/fonts/HelveticaNeueLTPro-Bd.ttf');
  }

  @font-face {
    font-family: 'HelveticaNeueLTPro-Blk';
    src: url('~@/assets/fonts/HelveticaNeueLTPro-Blk.ttf');
  }

  @font-face {
    font-family: 'HelveticaNeueLTPro-Lt';
    src: url('~@/assets/fonts/HelveticaNeueLTPro-Lt.ttf');
  }

  h1 {
    font-size: 1.6vw;
  }

  h2 {
    font-size: 1.4vw;
  }

  h3 {
    font-size: 1.1vw;
  }

  h4 {
    font-size: 1.0vw;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 1.6em;
    }

    h2 {
      font-size: 1.4em;
    }

    h3 {
      font-size: 1.1em;
    }

    h4 {
      font-size: 1.0em;
    }
  }

  h1, h2, h3, h4 {
    color: #057FAB;
    font-weight: bold;
    font-family: HelveticaNeueLTPro-Blk;
  }

  a {
    color: #057FAB;
  }

  .date {
    color: #007FB0;
    opacity: 0.5;
    font-family: HelveticaNeueLTPro-Blk;
  }

  .box-border-dotted {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke-width='4' stroke-dasharray='2%2c 4' stroke-dashoffset='0' stroke='lightblue' stroke-linecap='butt'/%3e%3c/svg%3e");
  }

  .box-border-dotted-left {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='1%' height='100%' fill='none' stroke-width='2' stroke-dasharray='2%2c 4' stroke-dashoffset='0' stroke='lightblue' stroke-linecap=''/%3e%3c/svg%3e");
  }

</style>

<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <FooterContactComponent />
  </v-app>
</template>

<script>
  import FooterContactComponent from "@/components/FooterContact.vue";

  /*
  import axios from 'axios'
  axios
    .get('/api/v1/contacts.json')
    .then(response => (this.contacts = response.data))
    .catch(err => console.error(err))
  */

  /*
  import { socket } from './services/socketio.service.js';

  socket.addDataReceiver((o_data) => {
    console.log('Receiver', o_data)
    switch (o_data.type) {
      case 'contacts':
        console.log('Contacts', o_data)
        break;
    }
  })

  socket.onSocketReady = () => {
    console.log('Socket ready fired !')
    socket.getData({
      source: 'contacts'
    });
  }
  */

  export default {
    name: "App",
    components: {
      FooterContactComponent
    },
    created() {
      //socket.setupSocketConnection();
    },
    beforeUnmount() {
      //socket.disconnect();
    },
    data: () => ({
    }),
  };
</script>

