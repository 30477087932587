<style scoped>

.Footer {
  font-family: HelveticaNeueLTPro-Lt;
}

.Footer .icon, .Footer .title {
  font-family: HelveticaNeueLTPro-Bd;
  color: #057FAB;
}

.y-divider {
  border-right-width: 10px;
  background-size: 5px 5px;
  background-image: linear-gradient(to bottom, #000 10%, rgba(255, 255, 255, 0) 15%);
  background-repeat: repeat-y;
}

.footer-big {
  display: block;
}

.footer-small {
  display: none;
}

@media screen and (max-width: 768px) {
  .footer-big {
    display: none;
  }

  .footer-small {
    display: block;
  }
}

</style>

<template>
  <div class="Footer mt-5 pt-5 bg-grey-lighten-2">
    <v-container class="about">

      <div class="footer-big">
          <v-row>
            <v-col cols="2">
            </v-col>
            <v-col cols="1" class="">
              <v-img
                  src="@/assets/icons/png/01_map_point.png"
                  width="50"
                  class="px-0"
              />
            </v-col>
            <v-col cols="2">
              <div class="title">Adresa:</div>
              <b>SBD STAVEG</b><br />
              Nušlova 2515/4<br/>
              158 00 Praha 5
            </v-col>
            <v-divider
                vertical
                class="border-dotted border-opacity-100"
            ></v-divider>
            <v-col cols="1">
              <v-img
                  src="@/assets/icons/png/02_phone_dark.png"
                  width="50"
                  class="px-0 mr-2"
              />
            </v-col>
            <v-col cols="2" class="text-left">
              <div class="title">Telefon:</div>
              (+420) 261 624 633
            </v-col>
            <v-divider
                vertical
                class="border-dotted border-opacity-100"
                :thickness="1"
                color="black"
            ></v-divider>
            <v-col cols="1">
              <v-img
                  src="@/assets/icons/png/03_envelope_dark.png"
                  width="50"
                  class="px-0 mr-2"
              />
            </v-col>
            <v-col cols="2">
              <div class="title">E-mail:</div>
              e-mail: <a href="mailto:staveg@staveg.cz">staveg@staveg.cz</a>
            </v-col>
            <v-col cols="2">
            </v-col>
          </v-row>
      </div>

      <div class="footer-small ml-10">
        <v-row>
          <v-col cols="1">
            <v-img
                src="@/assets/icons/png/01_map_point.png"
                width="50"
                class="px-0 mr-2"
            />
          </v-col>
          <v-col cols="">
            <div class="title">Adresa:</div>
            <b>SBD STAVEG</b><br />
            Nušlova 2515/4<br/>
            158 00 Praha 5
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <v-img
                src="@/assets/icons/png/02_phone_dark.png"
                width="50"
                class="px-0 mr-2"
            />
          </v-col>
          <v-col cols="">
            <div class="title">Telefon:</div>
            (+420) 261 624 633
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <v-img
                src="@/assets/icons/png/03_envelope_dark.png"
                width="50"
                class="px-0 mr-2"
            />
          </v-col>
          <v-col cols="">
            <div class="title">E-mail:</div>
            e-mail: <a href="mailto:staveg@staveg.cz">staveg@staveg.cz</a>
          </v-col>
        </v-row>
      </div>

      <v-divider
          horizontal
          :thickness="1"
          class="mt-8 border-opacity-100"
          color="black"
      ></v-divider>

      <v-row class="text-center mt-4">
        <v-col cols="12">
          Copyright © 2024 SBD Staveg
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "FooterContactComponent",

  data() {
    return {
    }
  }
}
</script>
